import React, { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface MetaHeadProps {
  title?: string;
  description?: string;
  pageName?: string;
  robots?: string;
  imageUrl?: string;
  hostUrl: string;
  staticAssetUrl: string;
  canonicalLink?: string;
}

const MetaHead: FC<MetaHeadProps> = ({
  title = 'Videos and webinars from business experts - BrightTALK',
  description = 'Free online events featuring the latest in B2B solution thinking from world-class visionaries, experts and innovators.',
  pageName,
  robots = 'index,follow',
  imageUrl = '',
  hostUrl,
  staticAssetUrl,
  canonicalLink,
}) => {
  const router = useRouter();

  const defaultHostUrl =
    hostUrl !== '' ? hostUrl : 'https://www.brighttalk.com';
  const canonicalUrl = canonicalLink
    ? canonicalLink
    : defaultHostUrl + router?.pathname;

  const defaultStaticAssetUrl =
    staticAssetUrl !== '' ? staticAssetUrl : 'https://assets.brighttalk.com';
  if (imageUrl == '') {
    imageUrl = staticAssetUrl + '/images/bt-it-logo.png';
  }

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="robots" content={robots}></meta>
      <meta httpEquiv="Expires" content="Wed, 11 Jan 1984 05:00:00 GMT" />
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, must-revalidate, max-age=0"
      />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta name="og:image" property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:site_name" content="BrightTALK" />
      <meta property="og:url" content={canonicalUrl} />
      <meta name="brighttalk-page-architecture" content="react" />
      <meta name="brighttalk-page-name" content={pageName} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="BrightTALK" />
      <meta name="twitter:creator" content="" />
      
      {/* Default favicons - uses the white background version for browsers not supporting media queries */}
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${defaultStaticAssetUrl}/images/favicon-apple-touch-icon.png?nocache=1`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${defaultStaticAssetUrl}/images/favicon-apple-touch-icon.ico?nocache=1`}
      />

      {/* Light browser theme favicon version */}
      <link
        rel="icon"
        type="image/png"
        href={`${defaultStaticAssetUrl}/images/favicon.ico?nocache=1`}
        media={'prefers-color-scheme: light'}
      />
      
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={`${defaultStaticAssetUrl}/images/favicon.png?nocache=1`}
        media={'prefers-color-scheme: light'}
      />     
      
      {/* Dark browser theme favicon version */}
      <link
        rel="apple-touch-icon"
        type="imgage/png"
        href={`${defaultStaticAssetUrl}/images/favicon-apple-touch-icon.png?nocache=1`}
        media={'prefers-color-scheme: dark'}
      />

       <link
        rel="icon"
        type="imgage/png"
        href={`${defaultStaticAssetUrl}/images/favicon-apple-touch-icon.ico?nocache=1`}
        media={'prefers-color-scheme: dark'}
      />     
      
      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};
export default MetaHead;
