import React, { FC } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faArrowDownWideShort,
  faArrowUpWideShort,
} from '@fortawesome/pro-regular-svg-icons';
import styles from './index.module.scss';
import { Row } from 'reactstrap';

export interface SectionHeadingsProps {
  title: string;
  description?: string;
  link?: string;
  linkTitle?: string;
  headingBlockName?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddLink?: string;
  linkNewTab?: boolean;
  classes?: string;
  isFilter?: boolean;
  handleSortOrder?: any;
  sortOrder?: string;
}

const SectionHeadings: FC<SectionHeadingsProps> = ({
  title,
  description,
  link,
  linkTitle,
  headingBlockName,
  dataBddTitle,
  dataBddDesc,
  dataBddLink,
  linkNewTab,
  classes,
  isFilter,
  handleSortOrder,
  sortOrder,
}) => {
  return (
    <Row>
      <div
        className={classnames(
          styles['bt-section-headings'],
          styles[classes],
          styles[headingBlockName],
          'generic-section-heading'
        )}
        data-bdd={headingBlockName}
      >
        <h2 data-bdd={dataBddTitle}>{title}</h2>
        {description && link && linkTitle ? (
          <p data-bdd={dataBddDesc}>
            {description}
            {link != '' && (
              <>
                {link === '/all-talks/' ? (
                  <a
                    href={link}
                    className={styles['bt-section-headings-link']}
                    data-bdd={dataBddLink}
                  >
                    {linkTitle}
                    <FontAwesomeIcon
                      className={styles['bt-section-headings-icon']}
                      icon={faAngleRight}
                    ></FontAwesomeIcon>
                  </a>
                ) : (
                  <a
                    className={styles['bt-section-headings-link']}
                    href={link}
                    data-bdd={dataBddLink}
                    target={linkNewTab ? '_blank' : ''}
                    rel="noreferrer"
                  >
                    {linkTitle}
                    <FontAwesomeIcon
                      className={styles['bt-section-headings-icon']}
                      icon={faAngleRight}
                    ></FontAwesomeIcon>
                  </a>
                )}
              </>
            )}
          </p>
        ) : (
          ''
        )}

        {isFilter && (
          <a className={styles['date-filter']} onClick={handleSortOrder} title={sortOrder === 'asc' ? 'Sort by Earliest' : 'Sort by Latest'}>
            Date{' '}
            <span className={styles['icon']} aria-hidden={'true'}>
              <FontAwesomeIcon
                icon={
                  sortOrder === 'asc' ? faArrowUpWideShort : faArrowDownWideShort
                }
              />
            </span>
          </a>
        )}
      </div>
    </Row>
  );
};

export default SectionHeadings;
